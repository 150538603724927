'use client'

import { useLayoutEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

const DatadogRenderedTiming = ({
	name,
}: {
	name: string
}) => {
	useLayoutEffect(() => {
		const startTime = Date.now()

		return () => {
			const endTime = Date.now()
			const timeRendered = endTime - startTime
			datadogRum.addTiming(name, timeRendered)
		}
	}, [
		name,
	])

	return null
}

export default DatadogRenderedTiming
